import React from 'react'
import MovLinks from './movLinks'


const Navbar = () => {
  return (
    <div className="flex flex-row items-center header-color w-5/6 h-[71px] rounded-2xl self-center p-[10px] m-3 mt-10 gap-x-4 max-w-screen-2xl">
      <div className="flex flex-col items-end ml-8 text-xl font-AccentFont text-white">
        <p className="h-4">Dav</p>
        <p>Ru</p>
      </div>
      <MovLinks/>
    </div>
  )
}

export default Navbar
