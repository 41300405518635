import React from 'react'
import {Navbar} from '.'
import ghIcon from '../Assets/ghicon.png'
import lnkIn from '../Assets/lnkin.png'

const Home = () => {
  return (
    <div className="flex w-full flex-col h-[900px] gap-y-48 items-center justify-between mb-20">
      <Navbar/>
      <div className="text-white flex flex-row">
        <div className="bg-white h-[2px] w-80 mt-5"/>
          <div className="mr-80">
            <h1 className="text-9xl font-black font-TitleFont h-28">DAVID RUIZ</h1>
            <h3 className="text-sm font-light self-end text-end font-TitleFont">Web Developer</h3>
        </div>
      </div>

      <nav className="flex flex-row justify-around gap-x-5 mb-14 ml-[1300px] selef-center">
        <a href="https://www.linkedin.com/in/davidruiz7/">
          <img src={lnkIn} width="30" height="30" alt='LinkedIn Icon'/>
        </a>
        <a href="https://github.com/DavRuiz07">
          <img src={ghIcon} width="30" height="30" alt='GitHub Icon'/>
        </a>
      </nav>
    </div>
  )
}

export default Home
