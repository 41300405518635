import React from 'react'
import Switch from './Switch'
import {Navbar} from './components'

function App() {
  return (
    <div className='w-max 2xl:w-full'>
      <Switch/>
    </div>
  );
}

export default App;
