import React from 'react'
import resume from '../Assets/davRuizResume.pdf'

function MovLinks(props){

  return(
    <div className="flex flex-row gap-x-4 justify-center">
      <div className="text-white flex flex-col p-1 self-center font-medium group">
      <a href='#about'>About</a>
      <div className="bg-white h-[2px] w-1/2 group-hover:w-full transition-[width]"/>
      </div>
      <div className="text-white flex flex-col p-1 self-center font-medium group">
      <a href='#projects'>Projects</a>
      <div className="bg-white h-[2px] w-1/2 group-hover:w-full transition-[width]"/>
      </div>
      <div className="text-white flex flex-col p-1 self-center font-medium group">
      <a href='#contact'>Contact</a>
      <div className="bg-white h-[2px] w-1/2 group-hover:w-full transition-[width]"/>
      </div>
      <div className="text-white flex flex-col p-1 self-center font-medium group">
      <a href={resume} target="_blank" rel="noopener noreferrer">Resume</a>
      <div className="bg-white h-[2px] w-1/2 group-hover:w-full transition-[width]"/>
      </div>
    </div>
  )
}

export default MovLinks
