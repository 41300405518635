import React from 'react'
import {SingleProject, Emoji} from '.'
import pr1 from '../Assets/proj1.png'
import pr2 from '../Assets/proj2.png'
import pr3 from '../Assets/proj3.png'

function Projects(props) {
  return (
    <div id='projects' className="my-32 flex flex-col">
      <div className="text-white mr-[1000px] self-center">
      <h1 className="text-4xl font-bold mb-4 font-TitleFont">Yeah, I work hard <Emoji symbol="💼"/></h1>
      <h2 className="text-xs font-MainText"> Each project is unique, Here are some of my work.</h2>
      </div>
    <div className="flex flex-col items-center justify-center mt-20 gap-y-7">
      <SingleProject name={"E-Commerce"} tStack={"React | Redux | JWT | Bycrpt | Express | PostgreSQL"} img={pr1} gitLink={"https://github.com/DavRuiz07/Store_Market"} webLink={"https://clothing-e-commerce.onrender.com"} desc={["Mock clothing E-Commerce Front-End & Back-End. Functional Cart, Administration Front-End, and User Authentification.","Created Front-End for Main Page, Admin Front-End & Authentification."]}/>

      <SingleProject name={"Fullstack Invaders"} tStack={"React | Redux | Phaser3 | Express"} img={pr2} gitLink={"https://github.com/Blackbox-Games-LLC/FullStack_InVaders"} webLink={"https://fullstack-invaders.onrender.com/"} desc={["Fullstack Invaders is a tower defense game based in our Solar System. The 9 levels include the 8 planets plus the dwarf planet Pluto.","Created game logic for enemy aliens & motherships, and front end for score screen."]}/>

      <SingleProject name={"BlackJack"} tStack={"React | Phaser 3"} img={pr3} gitLink={"https://github.com/DavRuiz07/Stackathon-BlackJack"} desc={["Single Player game of Blackjack with automatic dealer","Created all in game logic for dealer, card drawing, and game win/lose state."]}/>
    </div>
    </div>
  )
}

export default Projects
