import React, {useRef} from 'react'
import {About, Contact, Footer, Home, Projects} from './components'

function Switch () {
  const toProj = useRef()

  const goProj = () => {
    toProj.current?.scrollIntoView({behavior: 'smooth'})
  }
    return (
      <div className='bg-primary'>
      <Home/>
      <About/>
      <Projects/>
      <Contact/>
      <Footer/>
      </div>
    )
}

export default Switch
