import React from "react";
import ghIcon from '../Assets/ghicon.png'
import lnkIn from '../Assets/lnkin.png'
import mailico from '../Assets/mailIcon.png'
import resume from '../Assets/davRuizResume.pdf'

function Contact(){

  return(
    <div className='flex flex-col text-white mt-60 mb-52'>
      <h1 id='contact' className="text-4xl font-bold mb-10 text-white text-center font-TitleFont">Contact Me</h1>
      <p className='self-center font-MainText'>Feel Free to contact me through any of these links or shoot me an email!</p>
      <nav className="flex flex-row justify-center gap-x-5 mb-14 self-center m-10">
        <a href="https://www.linkedin.com/in/davidruiz7/">
          <img src={lnkIn} width="60" height="60" alt='LinkedIn Icon'/>
        </a>
        <a href="https://github.com/DavRuiz07">
          <img src={ghIcon} width="60" height="60" alt='GitHub Icon'/>
        </a>
        <a href="mailto:DavRuiz07@gmail.com">
          <img src={mailico} width="60" height="60" alt='Mail Icon'/>
        </a>
      </nav>
      <a id='resume' href={resume} target="_blank" rel="noopener noreferrer" className="text-4xl font-bold mb-10 text-slate-800 text-center font-TitleFont p-3 rounded-full bg-white w-fit self-center group">
        Resume
        <div className="bg-slate-800 h-[3px] w-1/2 group-hover:w-full transition-[width]"/>
      </a>
    </div>
  )
}

export default Contact
