import React from "react";
import {MovLinks} from ".";

function Footer(){

  return(
    <div className="w-full h-24 bg-gradient-to-r from-[#1B1B2FD9] to-[#1D1D2EC2] flex justify-center">
      <MovLinks/>
    </div>
  )
}

export default Footer
