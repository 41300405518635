import React from 'react'
import {Emoji} from '.'
import meAvatar from "../Assets/AvatarSelf.png"


function About() {
return (
  <div id='about' className="flex flex-col">
      <h2 className="text-xs mb-28 font-Titlefont w-3/12 text-white mr-[1000px] max-w-md self-center">Hello stranger!<Emoji symbol="👋"/> My Name is David Ruiz and I am a Web Devloper, passionate about digital products that help people experience everyday life, not endure it.</h2>
  <div className="flex flex-row justify-center items-center mt-14">
  <div className="flex flex-col justify-center font-MainText text-[#B9B9B9] text-xl gap-y-2 w-3/6 max-w-6xl">
      <h1 className="text-4xl font-bold mb-4 text-white font-TitleFont">About me</h1>
        <p className="max-w-fit text-justify">
        From a young age, I spent most of my time trying to figure out the inner workings of the bits of the world around me. I needed to know how things worked or what made them operate the way they did. Being reared on video games and building model kits, it’s really no surprise that I’ve spanned the gap and now focus on breaking down the inner workings of software so that I can build it myself. Is this what they call passion?</p>
        <p className="max-w-fit text-justify">
        I had my bachelor’s in software engineering in hand and was barely one foot off the college campus before I was pulled into the IT Sector. For about 4 years I spent my time navigating around the 5 boroughs completing IT projects and I think I may be one of the most well-traveled New Yorkers now. </p>
        <p className="max-w-fit text-justify">
        It was around the 4 year mark when COVID struck. The halt that the pandemic put on the world and work had me wondering what the next move was. I committed myself to make the most out of my quarantine and took up learning web development. I signed up for a web development boot camp and after months of rigorous training, I emerged victorious with enough knowledge to earn the title of fullstack developer.</p>
        <p className="max-w-fi text-justify">
        My new title in hand, I hit the ground running. The more jobs I took on, the more I realized I was ‘developing’ (sorry, had to) a deep passion for front-end development over the backend. I felt like this was where my creativity was able to thrive, and my best work seemed to be emerging. Don’t get me wrong though, I’m no slouch on the back end! I keep my fullstack skills sharp by tackling any style of project that may come across my desk. I’m always up for a challenge.</p>
  </div>
  <img src={meAvatar} alt='David_Avatar'/>
  </div>
  <div className='mt-10 flex gap-2 h-auto flex-wrap justify-center'>
      <p className="about-nub">JavaScript</p>
      <p className="about-nub">HTML</p>
      <p className="about-nub">CSS</p>
      <p className="about-nub">Node.js</p>
      <p className="about-nub">React</p>
      <p className="about-nub">Redux</p>
      <p className="about-nub">Express</p>
    </div>
  </div>
)
}

export default About
