import React from 'react'

function SingleProject(props){
const {name, desc, webLink, gitLink, img, tStack} = props
  return(
    <div className="flex flex-row max-w-fit w-[1100px] h-80 text-white justify-between rounded-[40px] bg-gradient-to-b from-[#3b3b60d9] to-[#1d1d2ec2]">
      <img className="scale-90 basis-5/12"src={img} alt="projectImg"/>
      <div className="flex flex-col items-start justify-between basis-7/12 font-MainText">
          <h1 className=" font-bold text-[32px] font-TitleFont mt-2">{name}</h1>
          <ul className="w-full">
            {desc.map((item,id) => {
              return(<li className="text-xl mr-4" key={id}>{item}</li>)
            })}
          </ul>
          <p className="text-center text-xl self-center font-extrabold">{tStack}</p>
          <div className="flex flex-row gap-x-3 mb-3">
          <div className="text-white flex flex-col self-start m-1 text-sm group">
          <a href={gitLink}>VISIT THE CODE</a>
          <div className="bg-white h-[2px] w-1/2 group-hover:w-full transition-[width]"/>
          </div>
          {webLink &&
          <div className="text-white flex flex-col self-start m-1 text-sm group">
          <a href={webLink}>VISIT THE SITE</a>
          <div className="bg-white h-[2px] w-1/2 group-hover:w-full transition-[width]"/>
          </div>
           }
           </div>
      </div>
    </div>
  )
}

export default SingleProject
